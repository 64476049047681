const init = () => {
  function addListenersToNav() {
    const menuToggle = document.querySelector(".js-menu-toggle");

    menuToggle?.addEventListener("click", (e) => {
      menuToggle.classList.toggle("active");
      document.getElementsByTagName("html")[0].classList.toggle("no-scroll");
      menuToggle.nextElementSibling?.classList.toggle("active");
      e.preventDefault();
    });
  }

  addListenersToNav();
};

export default init;
